import { GlobalPromotionCreate } from '../components/GlobalPromotion';

function GlobalPromotionCreateRoute() {
  return <GlobalPromotionCreate />;
}

export const Component = GlobalPromotionCreateRoute;

export function clientLoader() {
  return null;
}
